import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import './QuoteForm.css';
import axios from 'axios'; // For making API calls

const QuoteForm = () => {
  const [step, setStep] = useState(1);
  const [totalPrice, setTotalPrice] = useState(0);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    service: '',
    projectDescription: '',
    websiteType: '',
    platform: '',
    networkType: '',
  });

  const handleNext = () => setStep((prev) => Math.min(prev + 1, 3));
  const handlePrev = () => setStep((prev) => Math.max(prev - 1, 1));

  const updatePrice = (event) => {
    const price = parseInt(event.target.selectedOptions[0]?.getAttribute('data-price') || 0, 10);
    setTotalPrice((prev) => prev + price);
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePaymentRedirect = async () => {
    try {
      const response = await axios.post('https://royoltechnology.com/create-checkout', {
        totalPrice,
        formData,
      });

      if (response.data.checkoutUrl) {
        console.log('Redirecting to:', response.data.checkoutUrl);
        window.location.href = response.data.checkoutUrl; // Redirect to Square's payment page
      } else {
        alert('Failed to retrieve the payment link.');
      }
    } catch (error) {
      console.error('Error creating checkout:', error);
      alert('Failed to redirect to payment. Please try again.');
    }
  };

  return (
    <>
      <Helmet>
        <title>Request a Quote - Royol Technology</title>
        <meta
          name="description"
          content="Get a personalized quote for your web or app development project. Contact Royol Technology today!"
        />
      </Helmet>
      <section id="quote-section">
        <form id="quoteForm" onSubmit={(e) => e.preventDefault()}>
          <h2 className="contact-title">Get a Personalized Quote for Your Specific Needs</h2>
          {step === 1 && (
            <div className="form-step active-step">
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                required
                onChange={handleInputChange}
              />
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                required
                onChange={handleInputChange}
              />
              <input
                type="tel"
                name="phoneNumber"
                placeholder="Phone Number"
                required
                onChange={handleInputChange}
              />
              <input
                type="email"
                name="email"
                placeholder="Email"
                required
                onChange={handleInputChange}
              />
              <div className="button-container">
                <button type="button" onClick={handleNext}>
                  Next
                </button>
              </div>
            </div>
          )}
          {step === 2 && (
            <div className="form-step active-step">
              <select name="service" required onChange={updatePrice}>
                <option value="">Select Service</option>
                <option value="webDevelopment" >
                  Website Development 
                </option>
                <option value="appDevelopment" >
                  App Development 
                </option>
                <option value="networkingSolutions">
                  Networking Solutions
                </option>
              </select>
              {formData.service === 'webDevelopment' && (
                <select name="websiteType" required onChange={updatePrice}>
                  <option value="">Type of Website</option>
                  <option value="eCommerce" data-price="1000">
                    E-commerce
                  </option>
                  <option value="corporate" data-price="1000">
                    Corporate
                  </option>
                  <option value="blog" data-price="500">
                    Blog
                  </option>
                </select>
              )}
              {formData.service === 'appDevelopment' && (
                <select name="platform" required onChange={updatePrice}>
                  <option value="">Platform</option>
                  <option value="iOS" data-price="1500">
                    iOS
                  </option>
                  <option value="android" data-price="1500">
                    Android
                  </option>
                  <option value="crossPlatform" data-price="2000">
                    Cross-platform
                  </option>
                </select>
              )}
              {formData.service === 'networkingSolutions' && (
                <select name="networkType" required onChange={updatePrice}>
                  <option value="">Type of Network</option>
                  <option value="lan" data-price="500">
                    LAN
                  </option>
                  <option value="wan" data-price="800">
                    WAN
                  </option>
                  <option value="cloudNetworking" data-price="1200">
                    Cloud Networking
                  </option>
                </select>
              )}
              <textarea
                name="projectDescription"
                placeholder="Project Description"
                onChange={handleInputChange}
              ></textarea>
              <h3>Total Price: ${totalPrice}</h3>
              <div className="button-container">
                <button type="button" onClick={handlePrev}>
                  Prev
                </button>
                <button type="button" onClick={handleNext}>
                  Next
                </button>
              </div>
            </div>
          )}
          {step === 3 && (
            <div className="form-step active-step">
              <h3>Payment</h3>
              <div className="button-container">
                <button type="button" onClick={handlePrev}>
                  Prev
                </button>
                <button type="button" onClick={handlePaymentRedirect}>
                  Pay ${totalPrice}
                </button>
              </div>
            </div>
          )}
        </form>
      </section>
    </>
  );
};

export default QuoteForm;
